<template>
  <div style="background-color: white">

    <a-row style="padding: 40px">
      <a-col :span="12">
        <div style="font-size: 30px;font-weight: bold;"> {{ modelName }}</div>
        <div style="color: #666666">{{ model.frame }}</div>
        <br>
        <br>
        <div v-show="showDesc" @dblclick="switchDesc" style="color: #666666">
          {{ model.desc }}
        </div>
        <div v-show="!showDesc" >
          <a-textarea @blur="switchDesc" v-model:value="model.desc">

          </a-textarea>
        </div>
      </a-col>
      <a-col :span="12">
        <img height="150" width="500" :src="model.img" alt="none">
      </a-col>
    </a-row>
    <br><br>

    <a-row>
      <a-menu style="width: 100%;" @select="handleSelect" v-model:selectedKeys="current" mode="horizontal">
        <a-menu-item key="detail">
          <template #icon>
            <mail-outlined />
          </template>
          详细信息
        </a-menu-item>
        <a-menu-item key="code">
          <template #icon>
            <appstore-outlined />
          </template>
          代码
        </a-menu-item>
        <a-menu-item key="disc">
          <template #icon>
            <appstore-outlined />
          </template>
          讨论
        </a-menu-item>
        <a-menu-item key="info">
          <template #icon>
            <appstore-outlined />
          </template>
          模型信息
        </a-menu-item>
      </a-menu>
    </a-row>

    <a-row style="padding-left: 40px">
      <a-col :span="19">
        <div v-if="showDetail">
          <Markdown :highlight="true" v-if="!switchM" :source="model.detail" />
          <a-textarea v-if="switchM" v-model:value="model.detail" placeholder="" :rows="4" />

          <a-button @click="switchShow">编辑</a-button>
        </div>

        <div v-if="showScript">
          <Markdown v-if="!switchM" :source="model.script" />
          <a-textarea v-if="switchM" v-model:value="model.script" placeholder="" :rows="4" />

          <a-button @click="switchShow">编辑</a-button>
        </div>



        <div v-if="showDisc">
          <a-comment>
            <template #actions>
              <span key="comment-nested-reply-to">Reply to</span>
            </template>
            <template #author>
              <a>Han Solo</a>
            </template>
            <template #avatar>
              <a-avatar src="https://joeschmoe.io/api/v1/random" alt="Han Solo" />
            </template>
            <template #content>
              <p>
                We supply a series of design principles, practical patterns and high quality design
                resources (Sketch and Axure).
              </p>
            </template>
            <a-comment>
              <template #actions>
                <span>Reply to</span>
              </template>
              <template #author>
                <a>Han Solo</a>
              </template>
              <template #avatar>
                <a-avatar src="https://joeschmoe.io/api/v1/random" alt="Han Solo" />
              </template>
              <template #content>
                <p>
                  We supply a series of design principles, practical patterns and high quality design
                  resources (Sketch and Axure).
                </p>
              </template>
              <a-comment>
                <template #actions>
                  <span>Reply to</span>
                </template>
                <template #author>
                  <a>Han Solo</a>
                </template>
                <template #avatar>
                  <a-avatar src="https://joeschmoe.io/api/v1/random" alt="Han Solo" />
                </template>
                <template #content>
                  <p>
                    We supply a series of design principles, practical patterns and high quality design
                    resources (Sketch and Axure).
                  </p>
                </template>
              </a-comment>
              <a-comment>
                <template #actions>
                  <span>Reply to</span>
                </template>
                <template #author>
                  <a>Han Solo</a>
                </template>
                <template #avatar>
                  <a-avatar src="https://joeschmoe.io/api/v1/random" alt="Han Solo" />
                </template>
                <template #content>
                  <p>
                    We supply a series of design principles, practical patterns and high quality design
                    resources (Sketch and Axure).
                  </p>
                </template>
              </a-comment>
            </a-comment>
          </a-comment>
        </div>
        <div v-if="showInfo">

          <ModelInfo :modelName="modelName" :default="model.defaultWeight"></ModelInfo>
        </div>

      </a-col>
      <a-col :span="1"></a-col>
      <a-col :span="4">
        <a-list>
          <a-list-item>
            <div class="title">框架</div>
            {{ model.frame }}
          </a-list-item>
          <a-list-item>
            <div style="background-color: white;height: 500px">
              <div><a-tag>点云分类</a-tag></div>
              <a-tag>Transformer</a-tag>
              <a-tag>中文</a-tag>
              <a-tag>xxx</a-tag>
            </div>

          </a-list-item>
        </a-list>
      </a-col>
    </a-row>

  </div>
</template>
<script>
import Markdown from 'vue-markdown'
import axios from 'axios'
import ModelInfo from './model-info.vue'
export default{
  name:"model-desc",
  components:{
    ModelInfo,
    Markdown
  },
  data(){
    return{
      modelName: "",
      model: {},
      showDetail: true,
      showScript: false,
      showDisc: false,
      showInfo: false,
      switchM: false,
      showDesc:true,
      current:['1'],
    }
  },
  mounted(){
      const modelName = this.$route.query.modelName
      this.modelName=modelName
      const formData = new FormData()
      formData.append("modelName", modelName)
      axios.post("http://43.143.170.49:8466/api/aimodel/oneByName", formData).then(res => {
        this.model = res.data.data
        console.log("获取到的数据为：",this.model)
      })
  },
  methods:{
     switchDesc(){
      this.showDesc=!this.showDesc
      const formData=new FormData()
      formData.append("modelName",this.model.modelName)
      formData.append("desc",this.model.desc)
      axios.post("http://43.143.170.49:8466/api/aimodel/updateDesc1",formData).then(res=>{
        console.log(res)
      })
    },
     switchShow ()  {
      this.switchM = !this.switchM
      const formData=new FormData()
      formData.append("modelName",this.model.modelName)
      formData.append("script",this.model.script)
      formData.append("detail",this.model.detail)
      axios.post("http://43.143.170.49:8466/api/aimodel/updateDesc",formData).then(res=>{
        console.log(res)
      })
    },
     handleSelect  ({ item, key, selectedKeys })  {
      console.log(item, key, selectedKeys)
      if (key == "detail") {
        this.showDetail = true
        this.showScript = false
        this.showDisc = false
        this.showInfo = false
      }
      if (key == "code") {
        this.showDetail = false
        this.showScript = true
        this.showDisc = false
        this.showInfo = false
      }
      if (key == "disc") {
        this.showDetail = false
        this.showScript = false
        this.showDisc = true
        this.showInfo = false
      }
      if (key == "info") {
        this.showDetail = false
        this.showScript = false
        this.showDisc = false
        this.showInfo = true
      }
    }
  }
}
</script>
<style scoped>
.title {
  font-weight: bold;
  font-size: 25px;
  margin: 10px 0;
}
</style>