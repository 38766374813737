<template>
    <div>
        <a-row style="margin: 10px auto;">
            <a-input disabled :value="'执行脚本：' + model.predictScript"></a-input>
        </a-row>
        <a-table :columns="columns" :data-source="dataList" >
            <span slot="name" slot-scope="text">{{ text }}</span>
            <a slot="downloadUrl"></a>
            <span slot="id" slot-scope="id">
                <a-button @click="deleteWeight(id)">删除</a-button>
                <a-divider type="vertical" />
                <a v-if="id == defaultWeight" style="color: red;" disabled>默认权重</a>
                <a v-if="id != defaultWeight" @click="setDefaultWeight(id)">设置为默认值</a>
            </span>
        </a-table>

        <a-button @click="showModal">
            上传新权重
        </a-button>
        <a-modal style="width: 800px;" v-model:visible="visible" title="权重上传" ok-text="确认" cancel-text="取消">

            <a-layout>
                <a-layout>
                    <a-layout-content style="background-color: white;">


                        <a-form class="uploadForm" :model="formState" name="basic" :wrapper-col="{ span: 16 }"
                            autocomplete="off" @finish="onFinish">
                            <a-form-item :wrapper-col="{ span: 24 }" name="weightName" style="padding-bottom: 20px;"
                                :rules="[{ required: true, message: '请输入权重名称!' }]">
                                <a-input v-model:value="formState.weightName" placeholder="请输入权重名称" />
                            </a-form-item>


                            <a-form-item :wrapper-col="{ span: 24 }">
                                <a-form-item :wrapper-col="{ span: 24 }" name="file" no-style>
                                    <a-upload-dragger v-model:fileList="formState.file" name="files"
                                        :customRequest="customRequest">
                                        <p class="ant-upload-drag-icon"></p>
                                        <p class="ant-upload-text">点击选择或拖拽压缩包文件</p>
                                        <p class="ant-upload-hint">支持单个上传或多个上传</p>
                                    </a-upload-dragger>
                                </a-form-item>
                            </a-form-item>

                            <a-form-item :wrapper-col="{ span: 24 }">
                                <a-button type="primary" html-type="submit">上传</a-button>
                            </a-form-item>
                        </a-form>

                    </a-layout-content>
                </a-layout>
            </a-layout>
        </a-modal>
    </div>
</template>
<script>
import axios from 'axios'
import COS from 'cos-js-sdk-v5';
export default {
    name: "model-info",
    props: ["modelName","default"],


    data() {
        return {
            predictScript: "",//训练脚本
            dataList: [],
            defaultWeight: "",
            visible: false,
            formState: {
                weightName: "",
                file: null,
            },
            cosUrl: "",
            model: {},
            columns: [{
                title: "权重名称",
                key: 'name',
                dataIndex: 'name',
                width: 150,
            }, {
                title: "下载链接",
                key: 'cosUrl',
                dataIndex: 'cosUrl',
            }, {
                title: '操作',
                key: 'id',
                dataIndex: 'id',
                scopedSlots: { customRender: 'id' },
            }],


            customRequest: ({
                // action,
                // data,
                file,
                // filename,
                headers,
                // onError,
                onProgress,//这里作为参数传进来，让axios的回调进行使用
                onSuccess,
                withCredentials,
            }) => {
                var cos = new COS({
                    SecretId: 'AKIDd6IDsHQKzPZUWKs98rJUR6Udcy6xWnmX',
                    SecretKey: 'Zty1xa5NSLF5g2A5qMcfU3NRIuHQQBiz',
                });
                // console.log("action is"+action)
                console.log("headers is" + headers)
                console.log("withCredentials is " + withCredentials)

                cos.uploadFile({
                    Bucket: 'scanner3d-1316598877', /* 填写自己的 bucket，必须字段 */
                    Region: 'ap-nanjing',     /* 存储桶所在地域，必须字段 */
                    Key: file.name,              /* 存储在桶里的对象键（例如:1.jpg，a/b/test.txt，图片.jpg）支持中文，必须字段 */
                    Body: file, // 上传文件对象
                    SliceSize: 1024 * 1024 * 5,     /* 触发分块上传的阈值，超过5MB使用分块上传，小于5MB使用简单上传。可自行设置，非必须 */
                    onProgress: function (progressData) {
                        //想办法让下面的东西控制进度条
                        //自己输出，为了不破坏antd的onProgress内部构造，目前还不知道自己重写antd的onProgress如何显示进度条，那么就不改动他的了
                        console.log((progressData.loaded / progressData.total * 100).toFixed(2) + "%")//打开控制台可以看到更加精确的数值，下面的那个使用的整数（这个antd要求的）
                        onProgress({ percent: Math.round((progressData.loaded / progressData.total) * 100) }, file);//这个是antd给我们传入的

                    }
                }, function (err, cosData) {
                    if (err) {
                        console.log(err);
                        // onError(err)//调用外部的onError方法，让配置的antd的处理函数处理，下面同理
                    } else {
                        console.log(cosData)
                        data.cosUrl = cosData.Location
                        onSuccess(cosData, file)//手动调用onSuccess告诉antd完成上传了,保证任务完成的动画正确播放
                    }
                });

                return {
                    abort() {
                        console.log('upload progress is aborted.');
                    },
                };
            }

        }
    },
    mounted() {
        console.log("mounted执行")
        console.log("props.modelName", this.modelName,"hhh");
        axios.get("http://43.143.170.49:8466/api/aimodel/listCurrentAiModelWeight?modelName=" + this.modelName).then(res => {
            console.log(res);
            this.dataList = res.data.data
            this.defaultWeight = this.default
        })

        const formData = new FormData()
        formData.append("modelName", this.modelName)
        axios.post("http://43.143.170.49:8466/api/aimodel/oneByName", formData).then(res => {
            this.model = res.data.data
            console.log(this.model)
        })
    },
    methods: {

        showModal() {
            this.visible = true;
        },


        onFinish(values) {//上传完成之后，才能发送upload
            console.log('Success:', values);
            console.log(toRaw(values.file)[0].originFileObj);
            const formData = new FormData()
            formData.append("modelName", this.modelName)
            formData.append("cosUrl", this.cosUrl)
            formData.append("weightName", values.weightName)
            axios.post("http://43.143.170.49:8466/api/modelWeight/upload", formData).then(res => {
                console.log(res)
            })
        },

        deleteWeight(id) {
            console.log(id)
            const fd = new FormData()
            fd.append("id", id)
            axios.post("http://43.143.170.49:8466/api/modelWeight/delete", fd).then(res => {
                axios.get("http://43.143.170.49:8466/api/aimodel/listCurrentAiModelWeight?modelName=" + this.modelName).then(res => {
                    this.dataList = res.data.data
                    this.defaultWeight = this.default
                })
            })
        },

        setDefaultWeight(id) {
            const formData = new FormData();
            formData.append("modelName", this.modelName)
            formData.append("weightId", id)
            axios.post("http://43.143.170.49:8466/api/aimodel/setDefaultWeight", formData).then(res => {
                axios.get("http://43.143.170.49:8466/api/aimodel/listCurrentAiModelWeight?modelName=" + this.modelName).then(res => {
                    this.dataList = res.data.data
                    this.defaultWeight = id
                })
            })
        },
    }
}
</script>